// src/utils/smoothScroll.ts
export const smoothScroll = (() => {
  let scrollTimeout: number | null = null;

  return (targetId: string, duration = 800) => {
    if (scrollTimeout) {
      window.cancelAnimationFrame(scrollTimeout);
    }

    let targetPosition: number;
    if (targetId === "top") {
      targetPosition = 0; // Scroll to the top of the page
    } else {
      const element = document.getElementById(targetId);
      if (!element) return;
      targetPosition = element.getBoundingClientRect().top + window.pageYOffset;
    }

    const startPosition = window.pageYOffset;
    const startTime = performance.now();

    const ease = (t: number, b: number, c: number, d: number) => {
      t /= d / 2;
      if (t < 1) return (c / 2) * t * t + b;
      t--;
      return (-c / 2) * (t * (t - 2) - 1) + b;
    };

    const animateScroll = (currentTime: number) => {
      const timeElapsed = currentTime - startTime;
      const run = ease(timeElapsed, startPosition, targetPosition - startPosition, duration);
      window.scrollTo(0, run);
      if (timeElapsed < duration) {
        scrollTimeout = window.requestAnimationFrame(animateScroll);
      } else {
        window.scrollTo(0, targetPosition);
      }
    };

    scrollTimeout = window.requestAnimationFrame(animateScroll);
  };
})();
