// src/router/config.ts
const routes = [
  {
    path: ["/", "/home"],
    exact: true,
    component: "Home",
  },
  {
    path: "*",
    component: "NotFound",
  },
];

export default routes;
